import type { SetupIntentCreationResult } from '@orus.eu/backend/src/services/payment-method-service'
import { TechnicalError, ensureError } from '@orus.eu/error'
import { ContentContainerAppClient, PageTitle, useCrash, useTranslate } from '@orus.eu/pharaoh'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { useEffect, useState } from 'react'
import { trpc } from '../../client'
import { useNavigateBack } from '../../lib/hooks/use-navigate-back'
import { GlobalLoadingState } from '../molecules/global-loading-state'
import { PaymentMethodUpdateForm } from '../organisms/payment-update-form'

export default function PaymentMethodNewPage(): JSX.Element {
  const translate = useTranslate()
  const [setupIntentCreationResult, setSetupIntentCreationResult] = useState<SetupIntentCreationResult | 'loading'>(
    'loading',
  )
  const crash = useCrash()

  useEffect(() => {
    let cancelled = false

    trpc.paymentMethod.createSetupIntent
      .mutate()
      .then((result) => {
        if (cancelled) return
        setSetupIntentCreationResult(result)
      })
      .catch((err) => {
        if (cancelled) return
        crash({ type: 'unexpected-error', err: ensureError(err) })
      })

    return () => {
      cancelled = true
    }
  }, [crash])

  const navigate = useNavigate()
  const navigateBack = useNavigateBack()
  const searchParams = useSearch({ strict: false })

  const redirectStatus = searchParams.redirect_status
  const shouldRedirectToInvoices = redirectStatus === 'succeeded'

  useEffect(() => {
    if (shouldRedirectToInvoices) void navigate({ to: '/invoices', replace: true })
  }, [navigate, shouldRedirectToInvoices])

  if (redirectStatus && redirectStatus !== 'succeeded') {
    throw new TechnicalError('Todo : handle non success stripe return codes when updating payment method', {
      context: { redirectStatus },
    })
  }

  return (
    <>
      <PageTitle title={translate('payment_method_update_page_title')} />
      <ContentContainerAppClient>
        {shouldRedirectToInvoices || setupIntentCreationResult === 'loading' ? (
          <GlobalLoadingState />
        ) : (
          <PaymentMethodUpdateForm
            stripeClientSecret={setupIntentCreationResult.clientSecret}
            token={undefined}
            navigateBack={navigateBack}
            backButton
          />
        )}
      </ContentContainerAppClient>
    </>
  )
}
